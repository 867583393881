import { render, staticRenderFns } from "./Problem.vue?vue&type=template&id=5252a394&scoped=true&"
import script from "./Problem.vue?vue&type=script&lang=js&"
export * from "./Problem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5252a394",
  null
  
)

export default component.exports